<template>
  <layout-main>
    <div class="home">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>
              {{ $t('labels.interview') }}
            </h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.overview') }}</router-link>
            </div>
          </div>

          <div class="subheader-right">
            <v-guard :permissions="['tickets-export']">
              <b-dropdown v-if="!isDownloading" id="dropdown-1">
                <template v-slot:button-content><i class="fas fa-download"></i> {{ $t('labels.export') }}</template>

                <b-dropdown-group id="dropdown-group-1" header="Format">
                  <b-dropdown-item-button @click="$refs.ticketlist.exportResultsEstimate('tickets', 'excel')">Excel
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="$refs.ticketlist.exportResultsEstimate('tickets', 'csv')">CSV
                  </b-dropdown-item-button>
                </b-dropdown-group>
              </b-dropdown>
              <button v-if="isDownloading" class="btn btn-success"><i class="fas fa-circle-notch fa-spin"></i> {{ $t('labels.is-downloading') }}
              </button>
            </v-guard>
          </div>
        </div>
      </div>
      <!-- Header -->

      <div class="row">
        <tickets-list
            :enable-filter="$store.getters.permissions.includes('ticket-page-active-use-filter')"
            ref="ticketlist"
            :default-query="query"
            :title="this.$t('labels.active-tickets')"
        ></tickets-list>
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import Notifications from '@/components/dashboard/Notifications.vue';
import TicketsList from '@/components/lists/ticket/TicketsListQuality.vue';

export default {
  name: 'home',
  components: {
    LayoutMain,
    Notifications,
    TicketsList,
  },
  data() {
    return {
      isDownloading: false,
      query: {
        query: {
          bool: {
            must: [
              {
                match: {
                  is_interview_available_en: 'Yes',
                },
              },
              {
                match: {
                  is_interview_completed_en: 'No',
                },
              },
              {
                match: {
                  is_anonymized: 0,
                },
              },
            ],
          },
        },
      },
    };
  },
};
</script>
